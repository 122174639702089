import Layout from "../../components/Layout"
import { Link } from "gatsby"
import React from "react"
import Seo from "../../components/seo"

const ContactSuccessPage = () => {
  return (
    <Layout >
      <Seo title="Success! Contact" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading md:text-center">Contact</h1>

        
        <section className="max-w-prose mx-auto mt-10 lg:mt-20 ele-style lg:text-xl">
          <h2 className="success-heading">Success! <span role="img" aria-label="clapping hands">👏</span></h2>

          <p className="text-xl">I've received your form submission and intend to review it soon.
            Since this is a small business and I receive a lot of requests, it sometimes can take awhile to reply, but I aim to respond to everyone! <br />
            Thanks!
          </p>

          <p className="text-center"><Link to="/">Go to home</Link> <br /><br /> ...or explore different Talman <Link to="/guitars">guitars</Link> and <Link to="/basses">basses</Link></p>
        </section>
      </div>
    </Layout>
  )
}

export default ContactSuccessPage